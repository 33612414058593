.is--business .rwd002-container{
  @include media-up(lg) {
    margin-top: map-get($spacers, 7);
  }
  @include media-down(lg) {
    margin-top: map-get($spacers, 5);
  }
}

.is--business .rwd002-breadcrumbs {
  display: none;
}

.is--business .block-company{
  margin-bottom: 0;
}

.business-head{
  padding: 0 map-get($spacers, 3);
  margin-bottom: map-get($spacers, 7);
  &__lead{
    line-height: 2;
    max-width: 716px;
    margin: 0 auto;
    @include media-down(lg) {
      font-size: $font-size-sm;
    }
  }
}

.business-feature{
  background-color: #F1F1F1;
  padding: 0 map-get($spacers, 3);
  margin-bottom: map-get($spacers, 7);
  &__inner{
    max-width: 1100px;
    margin: 0 auto;
    @include media-up(lg) {
      display: grid;
      grid-template-rows: repeat( 2, auto);
      grid-template-columns: repeat( 2, 1fr);
    }
    @include media-down(lg) {
      padding-bottom: map-get($spacers, 4);
    }
  }

  &__body{
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    @include media-down(lg) {
      margin-bottom: map-get($spacers, 5);
    }
    @include media-down(lg) {
      margin-top: map-get($spacers, 4);
      margin-bottom: map-get($spacers, 3);
    }
  }

  &:nth-child(odd) &__body{
    @include media-up(lg) {
      grid-row: 1;
      grid-column: 1;
      margin-right: map-get($spacers, 6);
    }
  }

  &:nth-child(even) &__body{
    @include media-up(lg) {
      margin-left: map-get($spacers, 6);
    }
  }

  &:nth-child(odd) &__img{
    @include media-up(lg) {
      grid-row: 1;
      grid-column: 2;
    }
  }

  &__img{
    @include media-down(lg) {
      margin-left: calc( -1 * map-get($spacers, 3));
      margin-right: calc( -1 * map-get($spacers, 3));
    }
    img{
      display: block;
      max-width: 100%;
      height: auto;
      margin: 0 auto;
    }
  }

  &__btnwrap{
    display: flex;

    @include media-up(lg) {
      justify-content: flex-start;
      grid-column: 1 / span 2;
    }
    @include media-down(lg) {
      justify-content: center;
    }
  }

  &:nth-child(odd) &__btnwrap{
    @include media-up(lg) {
      justify-content: flex-end;
      padding-right: 50%;
    }
  }

  &:nth-child(even) &__btnwrap{
    @include media-up(lg) {
      justify-content: flex-start;
      padding-left: 50%;
    }
  }

  .title {
    font-size: calc(33 / 16 * $font-size-base);
    font-weight: $font-weight-bold;
    letter-spacing: 0.2em;
    margin-bottom: 0.7em;
    @include media-down(lg) {
      font-size: calc(28 / 16 * $font-size-base);
    }
  }
  .text{
    line-height: 2;
    letter-spacing: 0;
    &:last-child{
      margin-bottom: 0;
    }
    @include media-up(lg) {
      margin-right: -0.9em;
    }
    @include media-down(lg) {
      font-size: $font-size-sm;
    }
  }
}