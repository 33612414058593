@mixin c-module-title() {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 0.75em;
  font-weight: normal;
  letter-spacing: 0.22em;
  text-indent: 0.22em;
  font-size: $h2-font-size;
  @include media-down(lg) {
    font-size: 1.875rem;
  }
  @include media-down(md) {
    font-size: $h4-font-size;
  }

  span {
    font-family: "Roboto", $font-family-sans-serif;
    font-weight: 300;
  }

  &:after{
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    display: block;
    width: 82px;
    height: 1px;
    margin: auto;
    background: $primary;
  }
}

@mixin c-block-title($theme: $dark,$border-color: $primary){
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1em;
  span{
    position: relative;
    font-family: 'Roboto Condensed', $font-family-base;
    font-size: calc(40 / 16 * $font-size-base);
    font-weight: $font-weight-bold;
    padding-bottom: 0.1em;
    margin-bottom: 0.3em;
    @include media-down(lg) {
      font-size: calc(30 / 16 * $font-size-base);
    }
    &:after{
      content: '';
      position: absolute;
      top: 100%;
      left: calc(50% - 0.75em);
      height: 0;
      width: 1.5em;
      border-bottom: 1px solid $border-color;
    }
  }
  small{
    font-size: calc(13 / 16 * $font-size-base);
  }
}


@mixin c-compnay-title(){
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1.3em;
  span{
    position: relative;
    font-size: calc(25 / 16 * $font-size-base);
    font-weight: $font-weight-bold;
    letter-spacing: 0.3em;
    text-indent: 0.3em;
    padding-bottom: 0.8em;
    @include media-down(lg) {
      font-size: calc(20 / 16 * $font-size-base);
    }
    &:after{
      content: '';
      position: absolute;
      top: 100%;
      left: calc(50% - 1.25em);
      height: 0;
      width: 2.5em;
      border-bottom: 1px solid;
    }
  }

}