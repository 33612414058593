@keyframes scroll_line {
  0% {
    height: 6px;
    opacity: 0;
  }
  30% {
    height: 6px;
    opacity: 1;
  }
  60% {
    height: 70px;
  }
  100% {
    height: 0;
  }
}

.is--home .rwd002-container{
  margin-top: 0;
}

.mainvisual{
  display: flex;
  align-items: center;
  position: relative;
  background-color: $dark;
  color: $white;
  z-index: 0;
  @include media-down(lg) {
    height: 100vw;
    padding: map-get($spacers, 4);
  }
  @include media-up(lg) {
    justify-content: flex-end;
    height: calc(100vh - 100px);
    height: calc(100dvh - 100px);
  }

  &__lead{
    width: 100%;
    display: flex;
    flex-direction: column;
    font-size: min(9.6vw, calc(55 / 16 * $font-size-base));
    letter-spacing: 0.05em;
    font-feature-settings: "palt";
    font-weight: $font-weight-bold;
    @include media-up(lg) {
      max-width: 90%;
    }
    small{
      font-size: 0.42em;
      letter-spacing: 0.06em;
      margin-bottom: 0.8em;
      @include media-down(lg) {
        margin-bottom: 0.5em;
      }
    }
  }
  
  &__bg{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 80%;
    overflow: hidden;
    z-index: -1;
    @include media-down(lg) {
      width: 100%;
    }
  }

  &__bg:after{
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    background: url('/assets/img/home/main@2x.jpg') center / cover no-repeat fixed;
    transition: opacity 800ms ease-in-out;
    opacity: 0;
    @include media-down(xl) {
      background-attachment: scroll;
    }
    @include media-down(lg) {
      background-image: url('/assets/img/home/main_sp@2x.jpg');
    }
    .is-ready &{
      opacity: 1;
    }
  }

  &__scroll {
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    left: 8vw;
    bottom: 0px;
    height: 90px;
    color: $white;
    font-size: calc(10 / 16 * $font-size-base);
    @include media-down(lg) {
      display: none;
    }
    &:hover,
    &:focus {
      color: $white;
      text-decoration: none;
    }
    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 20px;
      left: 50%;
      background-color: $white;
      transform: translateX(-50%);
    }
    &:before {
      width: 1px;
      height: 70px;
    }
    &:after {
      width: 1px;
      height: 70px;
      background-color: #67F4FF;
      animation: scroll_line 3s ease-in-out infinite;
    }
    span {
      white-space: nowrap;
      font-family: "Roboto Condensed", $font-family-sans-serif;
      transform: rotate(90deg) translate(-50%, 0);
      transform-origin: center;
      letter-spacing: 0.1em;
      text-indent: 0.1em;
    }
  }
}

.block-news {
  @include media-up(lg) {
    display: flex;
  }
  &__title{
    @include c-block-title();
    @include media-up(lg) {
      align-items: flex-start;
      span:after{
        left: 0;
      }
    }
    @include media-down(lg) {
      margin-bottom: 0;
    }
  }
  &__head{
    @include media-up(lg) {
      display: flex;
      flex-direction: column;
      padding-top: map-get($spacers, 7);
      margin-left: auto;
      padding-right: map-get($spacers, 5);
      padding-left: map-get($spacers, 3);
    }
    @include media-down(lg) {
      padding-top: map-get($spacers, 3);
    }
  }

  &__catlist{
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    @include media-up(lg) {
      flex-direction: column;
    }
    @include media-down(lg) {
      padding: map-get($spacers, 3);
      flex-wrap: wrap;
      justify-content: center;
    }
  }
  
  &__catitem{
    @extend .text-truncate;
    display: inline-block;
    padding: 0.4em 0;
    font-size: calc(15 / 16 * $font-size-base);
    letter-spacing: 0.06em;
    font-weight: $font-weight-bold;
    max-width: 8em;
    @include media-down(lg) {
      padding: 0.5em;
    }
  }

  &__body{
    background-color: #F1F1F1;
    @include media-up(lg) {
      padding-top: map-get($spacers, 7);
      padding-bottom: map-get($spacers, 7) * 2;
      padding-right: map-get($spacers, 3);
      flex-basis: 80%;
    }
  }

  &__list{
    max-width: 1050px;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &__item {
    border: solid $white;
    border-width: 2px 0;
    &+&{
      border-top-width: 0;
    }
    .date{
      font-size: calc(13 / 16 * $font-size-base);
      letter-spacing: 0.06em;
      color: $primary;
      margin-right: 1em;
    }
    .cat{
      display: inline-block;
      padding: 0.1em 0.5em;
      background-color: $dark;
      color: $white;
      font-size: $font-size-xs;
      letter-spacing: 0.1em;
      font-weight: $font-weight-bold;
    }
    &.is-new &-head:after{
      content: 'NEW';
      display: inline-block;
      background-color: $primary;
      color: $white;
      font-weight: $font-weight-bold;
      font-size: calc(11 / 16 * $font-size-base);
      padding: 0.2em 0.5em;
      margin-left: 0.3em;
    }

    .subject{
      font-size: calc(15 / 16 * $font-size-base);
    }
  }

  &__item-head{
    margin-bottom: 0.5em;
  }

  &__item-link{
    display: block;
    &:hover,&:focus{
      text-decoration: none;
      .subject{
        text-decoration: underline;
      }
    }
    @include media-up(lg) {
      padding: 1em 1em 1em map-get($spacers, 5);
    }
    @include media-down(lg) {
      padding: 1em map-get($spacers, 3);
    }
  }

  &__btnwrap {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    max-width: 1050px;
    @include media-down(lg) {
      justify-content: center;
      padding: map-get($spacers, 5) map-get($spacers, 3);
    }
  }
}

.block-business {
  position: relative;
  max-width: calc( 100vw - 200px );
  margin-left: auto;
  margin-right: auto;
  margin-bottom: map-get($spacers, 7);
  background-color: $dark;
  padding: map-get($spacers, 5) map-get($spacers,3) 0;
  color: $white;
  z-index: 1;
  @include media-up(lg) {
    margin-top: map-get($spacers, 7) * -1;
  }
  @include media-down(lg) {
    max-width: 100%;
    margin-bottom: map-get($spacers, 5);
  }
  &__title{
    @include c-block-title($white,$white);
    margin-bottom: 1.5em;
  }
  &__lead{
    font-size: $font-size-base;
    line-height: 2;
    max-width: 600px;
    margin: 0 auto;
    @include media-down(lg) {
      font-size: $font-size-sm;
      margin: 0 -0.1em;
    }
  }
  &__btnwrap{
    display: flex;
    justify-content: center;
    padding: map-get($spacers, 5);
  }

  &__features {
    list-style: none;
    padding: 0;
    margin: 0 map-get($spacers, 3 ) * -1;
    display: flex;
    @include media-up(lg) {
      height: 280px;
    }
    @include media-down(lg) {
      flex-direction: column;
    }
  }

  &__feature{
    background: center / cover no-repeat;
    font-size: calc(18 / 16 * $font-size-base);
    font-weight: $font-weight-bold;
    letter-spacing: 0.15em;
    @include media-up(lg) {
      flex-basis: 20%;
    }
    a{
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      min-height: 150px;
      transition: $transition-base;
      &:hover,&:focus{
        text-decoration: none;
        background-color: fade-out($white, 0.8);
      }
    }
    &.is-feature01{
      background-image: url('/assets/img/home/business_hoon@2x.jpg');
      @include media-down(lg) {
        background-image: url('/assets/img/home/business_hoon_sp@2x.jpg');
      }
    }
    &.is-feature02{
      background-image: url('/assets/img/home/business_horei@2x.jpg');
      @include media-down(lg) {
        background-image: url('/assets/img/home/business_horei_sp@2x.jpg');
      }
    }
    &.is-feature03{
      background-image: url('/assets/img/home/business_taika@2x.jpg');
      @include media-down(lg) {
        background-image: url('/assets/img/home/business_taika_sp@2x.jpg');
      }
    }
    &.is-feature04{
      background-image: url('/assets/img/home/business_bankin@2x.jpg');
      @include media-down(lg) {
        background-image: url('/assets/img/home/business_bankin_sp@2x.jpg');
      }
    }
    &.is-feature05{
      background-image: url('/assets/img/home/business_bouon@2x.jpg');
      @include media-down(lg) {
        background-image: url('/assets/img/home/business_bouon_sp@2x.jpg');
      }
    }
  }
}

.block-works {
  overflow: hidden;
  margin-bottom: map-get($spacers, 7);
  @include media-down(lg) {
    padding: 0 map-get($spacers, 3);
    margin-bottom: map-get($spacers, 5);
  }
  &__title{
    @include c-block-title();
    margin-bottom: 1.5em;
  }

  &__list{
    list-style: none;
    padding: 0;
    @include make-row(2px);
    @include media-down(lg) {
      --#{$variable-prefix}gutter-x: 20px;
      --#{$variable-prefix}gutter-y: #{map-get($spacers, 3)};
    }
  }

  &__item{
    @include make-col-ready();
    @include make-col(3);
    @include media-down(lg) {
      @include make-col(6);
    }
    
    &-img{
      position: relative;
      overflow: hidden;
      aspect-ratio: 375 / 280;
      img{
        transition: $transition-base;
        display: block;
        max-width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &-link:hover &-img img{
      transform: scale(1.1);
    }

    &-link{
      &:hover,&:focus{
        text-decoration: none;
      }
    }
    
    &-body{
      padding: 1em 0;
      @include media-up(lg) {
        padding: 1em 5vw;
      }
    }

    .subject{
      font-size: $font-size-base;
      font-weight: $font-weight-bold;
      letter-spacing: 0.06em;
      line-height: 1.4;
      @include media-down(lg) {
        font-size: calc(14 / 16 * $font-size-base);
      }
    }
    .cat{
      @extend .text-truncate;
      position: absolute;
      top: 0;
      left: 0;
      display: inline-block;
      background: $dark;
      color: $white;
      font-size: $font-size-xs;
      font-weight: $font-weight-bold;
      letter-spacing: 0.1em;
      padding: 0.5em 1em;
      max-width: 90%;
      @include media-down(lg) {
        font-feature-settings: "palt";
        font-size: calc(11 / 16 * $font-size-base );
      }
    }
  }

  &__btnwrap {
    display: flex;
    justify-content: center;
    margin-top: map-get($spacers, 6);
    @include media-down(lg) {
      margin-top: map-get($spacers, 5);
    }
  }

}

.block-company {
  overflow: hidden;
  position: relative;
  margin-bottom: map-get($spacers, 7);
  z-index: 0;
  @include media-down(lg) {
    margin-bottom: 0;
  }
  &:after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: auto;
    background-color: $dark;
    transform-origin: left;
    transform: scale(0,1);
    transition: all 800ms ease-in-out;
    z-index: -2;
  }
  &.is-active:after{
    transform: scale(1,1);
  }
  &__bg{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #1A2F6F center / cover no-repeat;
    opacity: 0;
    transform: translateX(-50%);
    transition: all 800ms 600ms ease-in-out;
    z-index: -1;
    @include media-up(lg) {
      background-image: url('/assets/img/home/reason_bg@2x.jpg');
    }
    .is-active &{
      opacity: 1;
      transform: translateX(0);
    }
  }
  &__inner{
    padding: map-get($spacers, 7) map-get($spacers, 3);
    color: $white;
    transform: translateY(150px);
    transition: all 800ms 1200ms ease-in-out;
    opacity: 0;
    @include media-down(lg) {
      padding: map-get($spacers, 6) map-get($spacers, 3);
    }
    .is-active &{
      opacity: 1;
      transform: translateX(0);
    }
  }

  &__title{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2em;
    font-weight: $font-weight-bold;
    margin-left: -0.6em;
    margin-right: -0.6em;
    text-align: center;
    span{
      position: relative;
      font-size: calc(33 / 16 * $font-size-base);
      padding-bottom: 0.8em;
      margin-bottom: 0.3em;
      letter-spacing: 0.3em;
      @include media-down(lg) {
        font-size: calc(24 / 16 * $font-size-base);
      }
      &:after{
        content: '';
        position: absolute;
        top: 100%;
        left: calc(50% - 0.75em);
        height: 0;
        width: 1.5em;
        border-bottom: 1px solid $border-color;
      }
    }
    small{
      font-size: calc(18 / 16 * $font-size-base);
      letter-spacing: 0.2em;
      margin-bottom: 1.2em;
      @include media-down(lg) {
        font-size: $font-size-sm;
      }
    }
  }

  &__features{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    @include media-up(lg) {
      margin: 0 calc( -1 * map-get($spacers, 3));
    }
  }

  &__feature{
    width: 33.333%;
    @include media-up(lg) {
      max-width: 390px;
      padding: 0 map-get($spacers, 4);
    }
    @include media-down(lg) {
      width: 100%;
      margin-bottom: map-get($spacers, 6);
    }
    .name{
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: calc(23 / 16 * $font-size-base);
      font-weight: $font-weight-bold;
      letter-spacing: 0.1em;
      margin-bottom: 1em;
      img{
        display: block;
        max-width: min(100%, 21vw);
        height: auto;
        margin-bottom: 2em;
        @include media-down(lg) {
          margin-bottom: 1.6em;
        }
      }
    }
    .text{
      font-size: $font-size-base;
      line-height: 2;
      @include media-down(lg) {
        font-size: calc(15 / 16 * $font-size-base)
      }
    }
  }

  &__bnrwrap{
    display: flex;
    justify-content: center;
    padding: 0 map-get($spacers, 3);
    margin: map-get($spacers, 7) 0;
    @include media-down(lg) {
      margin: 0;
      padding: 0;
    }

    .banner{
      transition: $transition-base;
      @media (min-width:769px) {
        &:before,
        &:after{
          content: '';
          position: absolute;
          bottom: 22px;
          right: 0;
          background: left center / contain no-repeat;
          width: 36px;
          height: 6.3px;
          transition: $transition-base;
          transition-duration: 300ms;
        }
        &:after{
          background-image: svgUrlFunc('<svg viewBox="0 0 18.83 5.26" xmlns="http://www.w3.org/2000/svg"><path d="m0 4.76h17.62l-4.42-4.41" fill="none" stroke="#{$white}" stroke-miterlimit="10"/></svg>');
        }
        &:before{
          background-image: svgUrlFunc('<svg viewBox="0 0 18.83 5.26" xmlns="http://www.w3.org/2000/svg"><path d="m0 4.76h17.62l-4.42-4.41" fill="none" stroke="#{$black}" stroke-miterlimit="10"/></svg>');
        }

        &:hover:before{
          transform: translateX(25px);
        }
        &:hover:after{
          background-position: 25px center;
        }
      }
      img{
        display: block;
        max-width: 100%;
        height: auto;
        @include media-down(lg) {
          width: 100%;
        }
      }
    }
  }
}