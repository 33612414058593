@keyframes up_down_line {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-8px);
  }
}

.rwd002-footerSocial {
  display: flex;
  justify-content: center;
  align-items: center;
  
  @include media-up(lg) {
    padding: 0 3em;
  }
  @include media-down(lg) {
    flex-wrap: wrap;
    margin-top: 60px;
    margin-bottom: 60px;
  }
  &__title{
    letter-spacing: 0.16em;
    margin-right: 2em;
    font-size: $font-size-xs;
    font-family: 'Roboto', $font-family-sans-serif;
    @include media-down(lg) {
      width: 100%;
      text-align: center;
      margin-right: 0;
      margin-bottom: 1em;
      font-size: calc( 10 / 16 * $font-size-base);
    }
  }
  &__btn{
    @extend .btn;
    font-size: 1.3em;
    margin: 0 0.1em;
    line-height: 1;
    transition: $transition-base;
    color: inherit;
  }
  &__btn.is-fb{
    &:hover,&:focus{
      color: $sns-facebook-bg;
    }
  }
  &__btn.is-tw{
    &:hover,&:focus{
      color: $sns-twitter-bg;
    }
  }
  &__btn.is-line{
    &:hover,&:focus{
      color: $sns-line-bg;
    }
  }
}

.rwd002-pagetop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: -150px;
  width: 58px;
  height: 160px;
  font-size: $font-size-base;
  font-family: "Roboto Condensed", $font-family-base;
  color: $body-color;
  font-weight: $font-weight-bold;
  text-decoration: none;
  letter-spacing: 0.1em;
  transform-origin: bottom;
  background-color: #67F4FF;
  white-space: nowrap;

  @include media-up(lg) {
    right: map-get($spacers, 3);
  }
  @include media-down(lg) {
    // right: calc(50% - 50px);
    display: none;
  }
  &:before {
    content: "";
    display: block;
    margin-bottom: 1em;
    width: 6px;
    height: 23px;
    background: svgUrlFunc(
        '<svg viewBox="0 0 6.36 23.23" xmlns="http://www.w3.org/2000/svg"><path d="m.5 23.23v-22.02l5.5 5.52" fill="none" stroke="#{$body-color}" stroke-miterlimit="10"/></svg>'
      )
      no-repeat center / contain;
  }

  span {
    transform: rotate(90deg);
    margin-top: 1.5em;
    margin-bottom: 2em;
  }

  &:hover,
  &:focus {
    text-decoration: none;
    &:before {
      animation: up_down_line 0.9s infinite ease-in-out;
    }
  }
  &__wrap {
    width: 100%;
    z-index: 10;
    transition: $transition-base;
    @include media-up(lg) {
      position: sticky;
      bottom: 185px;
      margin: 0 auto;
      padding-left: map-get($spacers, 1);
      padding-right: map-get($spacers, 1);
    }
    @include media-down(lg) {
      position: relative;
    }
    &.hidden {
      transform: translateY(200px);
      opacity: 0;
    }
  }
}

.rwd002-footer-contact {
  display: flex;
  background: $dark;
  color: $white;
  @include media-down(lg) {
    display: none;
  }
  &__head,
  &__tel,
  &__form{
    flex-grow: 1;
    width: 33.333%;
    height: 160px;
    @include media-down(lg) {
      width: 50%;
    }
  }
  &__head{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: calc(15 / 16 * $font-size-base);
    letter-spacing: 0.06em;
    line-height: 1.93;
    span:after{
      display: inline-block;
      content: svgUrlFunc('<svg viewBox="0 0 80.23 6.86" xmlns="http://www.w3.org/2000/svg"><path d="m0 6.36h79.02l-6.02-6.01" fill="none" stroke="#fff" stroke-miterlimit="10"/></svg>');
      height: 7px;
      width: 80px;
      vertical-align: 0.2em;
      line-height: 0;
      margin-left: 0.5em;
    }
  }
  &__tel{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-color: lighten($dark, 10%);
    .opentime{
      font-size: $font-size-sm;
      display: block;
      width: 100%;
      margin-top: 0.6em;
    }
  }
  &__tellink{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: 'Roboto', $font-family-base;
    letter-spacing: 0.05em;
    font-size: calc( 30 / 16 * $font-size-base);
    padding-top: 0.7em;
    height: 100%;
    width: 100%;
    transition: $transition-base;
    .fa-phone{
      margin-right: 0.7em;
    }
    &:hover,&:focus{
      text-decoration: none;
      background-color: darken($dark, 7.5%);
    }
  }
  &__form {
    display: flex;
    .btn{
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
      fill: $white;
      font-size: inherit;
      letter-spacing: 0.06em;
    }
    .btn .icon{
      margin-right: 1em;
      aspect-ratio: 23/18;
      @include media-down(lg) {
        width: 17px;
      }
    }
  }
}

.rwd002-footer {
  background-color: $dark;
  color: $white;
  @include media-up(md) {
    padding: map-get($spacers, 7) map-get($spacers, 5) map-get($spacers, 3);
  }
  @include media-down(lg) {
    padding-top: 90px;
  }
  @include media-down(md) {
    padding: 90px map-get($spacers, 4) 0;
  }

  &__inner {
    display: flex;
    align-items: stretch;
    max-width: 1100px;
    margin: 0 auto;
    @include media-down(lg) {
      flex-direction: column;
      align-items: stretch;
    }
  }

  &__info {
    @include media-up(lg) {
      flex-grow: 1;
      padding-right: map-get($spacers, 5);
    }
    @include media-down(lg) {
      margin-bottom: 3em;
    }
  }

  &__companyTitle{
    font-size: $font-size-base;
    margin-bottom: 1.5em;
    @include media-down(lg) {
      font-size: calc(15 / 16 * $font-size-base);
      letter-spacing: 0.06em;
      text-align: center;
    }
  }
  &__companyName {
    font-size: $font-size-base * 1.375;
    font-weight: $font-weight-bold;
    letter-spacing: 0.2em;
    font-feature-settings: "palt";
    @include media-up(lg) {
      display: grid;
      grid-template-columns: auto 1fr;
      grid-template-rows: repeat(2, auto);
    }
    @include media-down(lg) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    &-logo{
      @include hoverOpacity;
      grid-row: 1 / span 2;
      align-self: center;
      @include media-up(lg) {
        margin-right: 2.5em;
      }
      @include media-down(lg) {
        margin-bottom: 1em;
      }
    }
    &-text{
      font-size: calc(22 / 16 * $font-size-base);
      font-weight: $font-weight-bold;
      letter-spacing: 0.06em;
      margin-bottom: 0.3em;
      @include media-down(lg) {
        font-size: calc(18 / 16 * $font-size-base);
        text-align: center;
        margin-bottom: 0.5em;
      }
    }
    &-addr{
      font-weight: $font-weight-normal;
      font-size: calc(15 / 16 * $font-size-base);
      letter-spacing: 0.1em;
      &:last-child{
        margin-bottom: 0;
      }
      @include media-down(lg) {
        font-size: $font-size-sm;
        text-align: center;
      }
    }
  }
  &__companyLicense{
    margin-top: 2.5em;
    font-size: calc(13 / 16 * $font-size-base);
    letter-spacing: 0.04em;
    &:last-child{
      margin-bottom: 0;
    }
    @include media-down(lg) {
      margin-left: -1em;
      margin-right: -1em;
      font-size: 3.2vw;
      text-align: center;
    }
  }

  &__address {
    font-size: $font-size-sm;
    color: tint($dark, 50%);
    letter-spacing: 0.19em;
    font-feature-settings: "palt";
    &:last-child {
      margin-bottom: 0;
    }
    .zip,
    .addr2 {
      display: none;
    }
  }

  &__foot{
    @include media-up(lg) {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 80px;
    }
  }

  &__copyright,
  &__login {
    display: block;
    text-align: center;
    font-size: $font-size-xxs;
    letter-spacing: 0.14em;
    text-indent: 0.14em;
    font-feature-settings: "palt";
  }

  &__copyright {
    padding: 0 3em;
  }

  &__login {
    color: darken($white, 30%);
    @include media-down(lg) {
      margin-top: 2em;
      background-color: lighten($dark, 10%);
      padding: map-get($spacers, 3);
      margin-left: map-get($spacers, 4) * -1;
      margin-right: map-get($spacers, 4) * -1;
    }
    @include media-up(lg) {
      text-align: right;
    }
  }
}

.rwd002-footerNav {
  position: relative;
  @include media-up(lg) {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    flex-basis: 50%;
  }
  @include media-down(lg) {
    display: flex;
    flex-wrap: wrap;
  }
  @include media-down(md) {
    display: block;
  }
  &:after{
    content: '';
    position: absolute;
    background-color: $white;
    @include media-up(lg) {
      top: 5%;
      bottom: 5%;
      right: 100%;
      height: auto;
      width: 1px;
    }

  }
  &__list,
  &__sublist {
    list-style: none;
    padding: 0;
    margin: 0;
    @include media-up(lg) {
      width: 100%;
      max-width: 400px;
      margin-left: auto;
    }
  }
  &__list {
    font-size: $font-size-sm;
    @include media-up(md) {
      column-count: 3;
      column-gap: 1em;
    }
    @include media-up(lg) {
      grid-column: 1;
      grid-row: 1 / span 2;
      column-gap: 0;
    }
    @include media-down(lg) {
      margin-right: 2.5em;
    }
    @include media-down(md) {
      flex-grow: 1;
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 0.5em;
    }
  }
  &__sublist {
    font-size: $font-size-sm;
    @include media-up(md) {
      column-count: 2;
      column-gap: 1em;
    }
    @include media-down(md) {
      display: flex;
      flex-wrap: wrap;
    }
  }
}

.rwd002-footerNav-item {
  display: flex;
  flex-wrap: wrap;
  @include media-up(md) {
    padding-right: 1em;
  }
  @include media-down(md) {
    position: relative;
    margin-bottom: .5em;
  }
  &__link {
    position: relative;
    display: inline-block;
    padding: 0.8em 0;
    letter-spacing: 0.14em;
    font-feature-settings: "palt";
    &:hover,
    &:focus {
      color: $white;
    }
    @include media-down(md) {
      flex-grow: 1;
      flex-basis: calc(100% - 40px);
      padding: 0.65em 40px 0.65em 2.7em;
      background-color: lighten($dark, 10%);
      &:before {
        position: absolute;
        content: svgUrlFunc('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path fill="#{$white}" d="M64 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L178.8 256L41.38 118.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C80.38 444.9 72.19 448 64 448z"/></svg>');
        height: 1.3em;
        width: 0.4em;
        top: 0.65em;
        left: 1em;
      }
    }
  }
}

.rwd002-footerNav-subitem {
  &__link {
    display: inline-block;
    padding: 0.8em 0;
    margin-bottom: 0.5em;
    letter-spacing: 0.14em;
    transition: $transition-base;
    &:hover,
    &:focus {
      color: $white;
    }
    @include media-down(lg) {
      margin-bottom: 0;
      margin-right: 2em;
    }
  }
}

.rwd002-footer-subnav {
  @include media-up(md) {
    display: none;
  }
  &__tgl {
    @extend .btn;

    &:focus {
      box-shadow: none;
    }

    @include media-up(md) {
      display: none;
    }
    @include media-down(md) {
      position: relative;
      flex-basis: 40px;
      &:before,
      &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: calc(50% - 5px);
        width: 10px;
        height: 1px;
        background-color: darken($white, 50%);
        transition: transform 0.2s ease-in-out;
      }

      &:after {
        transform: rotate(-90deg);
      }

      &.is-active {
        &:before {
          transform: rotate(180deg);
        }
        &:after {
          transform: rotate(0deg);
        }
      }
    }
  }
  &__list {
    list-style: none;
    @include media-down(md) {
      padding-left: 1.7em;
      padding-bottom: 1em;
    }
  }
}

.rwd002-footer-subnav-item {
  &__link {
    @include media-down(md) {
      display: block;
      padding: 0.2em 0;
    }
  }
}

.rwd002-footer-search {
  margin-top: 2em;
  @include media-down(lg) {
    width: 100%;
    margin-top: 1.5em;
    margin-bottom: 3em;
  }
  &__inner {
    position: relative;
    max-width: 320px;
    @include media-down(md) {
      margin-left: -0.8em;
      margin-right: -0.8em;
    }
  }
  &__input {
    @extend .form-control;
    padding-right: 2.2em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    background-color: tint($dark, 10%);
    transition: $transition-base;
    border-width: 0;
  }
  &__btn {
    @extend .btn;
    position: absolute;
    top: 0;
    bottom: 0;
    right: calc(100% - 2.2em);
    width: 2.2em;
    padding: 0 0.5em;
    pointer-events: none;
    transition: $transition-base;
    > svg {
      width: 1em;
      height: 100%;
      fill: tint($dark, 30%);
    }
  }
  &__btn:focus,
  &__input:focus + &__btn {
    pointer-events: auto;
    right: 0;
  }
}
