@font-face {
    font-family: 'Roboto Condensed';
    src: url('../fonts/Roboto_Condensed/roboto_condensed-light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto Condensed';
    src: url('../fonts/Roboto_Condensed/roboto_condensed-regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto Condensed';
    src: url('../fonts/Roboto_Condensed/roboto_condensed-bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto Condensed';
    src: url('../fonts/Roboto_Condensed/roboto_condensed-lightitalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto Condensed';
    src: url('../fonts/Roboto_Condensed/roboto_condensed-italic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto Condensed';
    src: url('../fonts/Roboto_Condensed/roboto_condensed-bolditalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
}