.is--company .rwd002-breadcrumbs{
  display: none;
}
.is--company .rwd002-container{
  @include media-up(lg) {
    margin-top: map-get($spacers, 7);
  }
  @include media-down(lg) {
    margin-top: map-get($spacers, 5);
  }
}

.company-greeting {
  overflow: hidden;
  padding: 0 map-get($spacers, 3);
  margin-bottom: map-get($spacers, 7) + 1.5;
  @include media-down(lg) {
    margin-bottom: map-get($spacers, 6);
  }
  &__title{
    @include c-compnay-title();
    margin-bottom: 2em;
  }

  &__row{
    position: relative;
    display: flex;
    max-width: 1100px;
    margin: 0 auto;
    z-index: 0;
    @include media-down(lg) {
      flex-direction: column;
      align-items: center;
    }
    &:after{
      content: '';
      position: absolute;
      top: 50px;
      left: 165px;
      bottom: 0;
      width: 100vw;
      background-color: #F1F1F1;
      z-index: -1;
      @include media-down(lg) {
        top: 50vw;
        left: calc( -1 * map-get($spacers, 3));
      }
    }
  }

  &__image{
    flex-shrink: 0;
    @include media-up(lg) {
      margin-right: map-get($spacers, 5);
    }
    @include media-down(lg) {
      max-width: 61vw;
      margin-bottom: map-get($spacers, 3);
    }
    img{
      display: block;
      max-width: 100%;
      margin: 0 auto;
      height: auto;
    }
  }

  &__signature {
    font-weight: $font-weight-bold;
    margin-top: 1.5em;
    @include media-up(lg) {
      padding-bottom: map-get($spacers, 4);
    }
    .title{
      display: inline-block;
      font-size: calc(18 / 16 * $font-size-base);
      letter-spacing: 0.2em;
      margin-right: 1.2em;
    }
    .name{
      display: inline-flex;
      flex-direction: column;
      font-size: calc(28 / 16 * $font-size-base);
      letter-spacing: 0.2em;
      &:last-child{
        margin-bottom: 0;
      }
      small{
        font-size: $font-size-xs;
        letter-spacing: 0.2em;
        color: #7F8080;
        font-weight: $font-weight-normal;
      }
    }
  }
  &__body{
    line-height: 2;
    font-size: calc(17 / 16 * $font-size-base);
    letter-spacing: 0.04em;
    @include media-up(lg) {
      display: flex;
      align-items: center;
      margin-top: map-get($spacers, 5);
      padding: map-get($spacers, 5) 0;
    }
    @include media-down(lg) {
      font-size: calc(15 / 16 * $font-size-base);
      padding-bottom: map-get($spacers, 5);
    }
    .text{
      &:last-child{
        margin-bottom: 0;
      }
    }
  }
}

.company-philosophy {
  padding: 0 map-get($spacers, 3);
  margin-bottom: map-get($spacers, 7) * 1.5;
  @include media-down(lg) {
    margin-bottom: map-get($spacers, 6);
  }
  &__title{
    @include c-compnay-title();
  }
  &__lead {
    text-align: center;
    font-weight: $font-weight-bold;
    font-size: calc(26 / 16 * $font-size-base);
    line-height: 1.8;
    margin-bottom: map-get($spacers, 7);
    @include media-down(lg) {
      font-size: calc(20 / 16 * $font-size-base);
      margin-bottom: 65px;
    }
  }
  &__img{
    display: block;
    max-width: 100%;
    height: auto;
    margin: map-get($spacers, 7) auto;
    @include media-down(lg) {
      margin: 65px auto map-get($spacers, 6);
    }
  }
}

.company-outline {
  padding: 0 map-get($spacers, 3);
  margin-bottom: map-get($spacers, 7) * 1.5;
  @include media-down(lg) {
    margin-bottom: map-get($spacers, 6);
  }
  &__title{
    @include c-compnay-title();
  }

  &__table{
    max-width: 960px;
    margin: 0 auto;
    border-collapse: separate;
    border-spacing: 0;
    border-bottom: 1px solid tint($body-color, 70%);
    th,td{
      font-size: $font-size-base;
      letter-spacing: 0.1em;
      font-weight: $font-weight-normal;
      border: solid tint($body-color, 70%);
      border-width: 1px 0 0;
      padding: 0.5em 1.2em;
      @include media-down(lg) {
        font-size: calc(13 / 16 * $font-size-base);
        letter-spacing: 0.05em;
        @include media-down(lg) {
          padding: 0.3em 0.2em;
        }
      }
    }
    th {
      white-space: nowrap;
      @include media-down(lg) {
        padding-right: 2em;
      }
    }
    td span{
      word-break: keep-all;
    }
  }
}

.company-access {
  padding: 0 map-get($spacers, 3);
  margin-bottom: 0;
  &__title{
    @include c-compnay-title();
  }
  &__map{
    overflow: hidden;
    margin-left: calc(-1 * map-get($spacers, 3));
    margin-right: calc(-1 * map-get($spacers, 3));
    iframe{
      display: block;
      height: 550px + 300px;
      margin-top: -150px;
      margin-bottom: -150px;
      @include media-down(lg) {
        height: calc(100vw + 300px);
      }
    }
  }
}